.detailPlanWrapperItem .price:first-of-type{
    margin-right: 6px;
}
.PlanComparisonWrapperItem .planComparisonPrice:first-of-type{
    margin-right: 6px;
}
.divSelect .MuiSelect-select {
    font-size: 14px !important;
}
.divSelect .MuiMenuItem-root{
    font-size: 14px !important;
}

#mui-5 .MuiMenuItem-root{
    font-size: 14px !important;
}
/*.wrapperPlanComparison {*/
/*    width: max-content !important;*/
/* }*/
