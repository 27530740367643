.MuiBox-root{
    /*height: auto !important;*/
    /*width: auto !important;*/
}
.link{
    text-decoration: underline;
    color: rgb(0, 106, 198);
    cursor: pointer;
}

.divUploadLogo{
    right: 115px !important;
    top: 130px !important;
}

.css-7ldjcq-MuiGrid-root>.MuiGrid-item{
    padding-left: 0px  !important; 
}

.divNameLogo{
    right: 190px !important;
    top: 140px  !important;
}