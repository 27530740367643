/*.MuiBox-root{*/
/*    !*height: 90% !important;*!*/
/*    !*width: auto !important;*!*/
/*}*/
/*.link{*/
/*    text-decoration: underline;*/
/*    color: rgb(0, 106, 198);*/
/*    cursor: pointer;*/
/*}*/

/*.CardBoxV2 img{*/
/*    margin-bottom: 20px;*/
/*    max-height: 55px;*/
/*}*/

.css-7ldjcq-MuiGrid-root>.MuiGrid-item{
    padding-left: 0 !important;
}

.css-7ldjcq-MuiGrid-root{
   margin-left: 0 !important;
}

/*.CardBoxV3{*/
/*    margin-right: 30px;*/
/*    padding-right: 30px;*/
/*    max-height: 35px;*/
/*}*/

/*.divCardContainer{*/
/*    gap: 14px*/
/*}*/

/*.divUploadLogoSponsor{*/
/*    right: 137px !important;*/
/*    top: 194px !important;*/
/*}*/

/*.divNameLogoSponsor{*/
/*    right: 300px !important;*/
/*}*/

/*.wrapperCardAddNewList{*/
/*    width: 190px;*/
/*}*/